<template>
    <div>
        <a-modal :title="title" :visible="showDialog" :confirm-loading="confirmLoading" ok-text="确认" cancel-text="取消"
            @ok="handleOk" @cancel="handleCancel">
            <a-form ref="formRef" :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                <a-form-item label="姓名">
                    <a-input v-decorator="['name', nameConfig]" placeholder="请输入姓名" />
                </a-form-item>
                <a-form-item label="拍摄团队">
                    <a-input v-decorator="['photographer', photographerConfig]" placeholder="请输入拍摄团队" />
                </a-form-item>
                <a-form-item ref="phone" label="电话">
                    <a-input v-decorator="['phone', phoneConfig]" placeholder="请输入电话号码" />
                </a-form-item>
                <a-form-item ref="idCard" label="身份证号码">
                    <a-input v-decorator="['idCard', idCardConfig]" placeholder="请输入身份证号码" />
                </a-form-item>
                <a-form-item ref="date" label="日期">
                    <a-date-picker v-decorator="['date', dateConfig]" :disabled-date="disabledDate" format="YYYY-MM-DD"
                        placeholder="请选择日期" />
                </a-form-item>
                <a-form-item label="时间">
                    <a-select v-decorator="['timeInterval', timeIntervalConfig]" placeholder="请选择时间">
                        <a-select-option value="0">上午</a-select-option>
                        <a-select-option value="1">下午</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import { createData, editData } from "@/plugins/request/zyOrder";
export default {
    name: "zyOrderAddOrEdit",
    // props: {
    //     addOrEditDialog: Boolean,
    //     dialogTitle: String
    // },
    data() {
        return {
            id: "",
            formData: {
                name: "",
                photographer: "",
                phone: "",
                idCard: "",
                date: "",
                timeInterval: ""
            },
            showDialog: false,
            title: "新增",
            confirmLoading: false,
            nameConfig: {
                rules: [{ required: true, message: "请输入姓名！" }],
            },
            photographerConfig: {
                rules: [
                    { required: true, message: "请输入拍摄团队！" },
                    { min: 2, message: "最少2个字符！" },
                ],
            },
            phoneConfig: {
                rules: [
                    {
                        required: true,
                        validator: function (rule, value, callback) {
                            if (!value) {
                                callback("请输入电话号码！");
                            }
                            const mobileReg = /^1(3|4|5|6|7|8|9)\d{9}$/;
                            if (!mobileReg.test(value)) {
                                callback("请输入正确的电话号码！");
                            } else {
                                callback();
                            }
                        },
                    },
                ],
            },
            idCardConfig: {
                rules: [
                    {
                        required: true,
                        validator: function (rule, value, callback) {
                            if (!value) {
                                callback("请输入身份证号码！");
                            }
                            const mobileReg =
                                /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
                            if (!mobileReg.test(value)) {
                                callback("请输入正确的身份证号码！");
                            } else {
                                callback();
                            }
                        },
                    },
                ],
            },
            dateConfig: {
                rules: [
                    { required: true, message: "请输入日期！" },
                    // { validator: this.idCardValidator() }
                ],
            },
            timeIntervalConfig: {
                rules: [
                    { required: true, message: "请输入时间！" },
                    // { validator: this.idCardValidator() }
                ],
            },
        };
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "addOrder" });
    },
    mounted() {
        // this.showDialog = this.$props.addOrEditDialog;
        // this.title = this.$props.title;
    },

    methods: {
        disabledDate(current) {
            return current && current < moment().endOf("day");
        },
        openDialog(title, data) {
            this.showDialog = true;
            this.title = title;
            this.id = "";
            let defaultData = {
                idCard: "",
                name: "",
                date: "",
                phone: "",
                timeInterval: "",
                photographer: "",
            };
            this.$nextTick(() => {
                if (data) {
                    this.id = data.id;
                    defaultData = {
                        idCard: data.idCard,
                        name: data.name,
                        photographer: data.photographer,
                        date: moment(data.date),
                        phone: data.phone,
                        timeInterval: data.timeInterval,
                    };
                }
                this.form.setFieldsValue(defaultData);
            });
        },
        handleOk(e) {
            this.form.validateFields((err, values) => {
                if (!err) {
                    values.date = moment(values.date).format("YYYY-MM-DD");
                    if (this.title == "编辑预约") {
                        values.id = this.id;
                        this.confirmLoading = true;
                        editData(values).then((res) => {
                            this.$emit("refreshTable");
                            this.showDialog = false;
                            this.confirmLoading = false;
                        });
                    } else {
                        this.confirmLoading = true;
                        createData(values).then((res) => {
                            this.$emit("refreshTable");
                            this.showDialog = false;
                            this.confirmLoading = false;
                        });
                    }

                } else {
                    return;
                }
            });
        },
        handleCancel(e) {
            this.showDialog = false;
        },
        onSubmit() { },
        MobileNumberValidator(rule, value, callback) {
            const mobileReg = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (!mobileReg.test(value)) {
                callback("请输入正确的电话号码");
            } else {
                callback();
            }
        },
        idCardValidator(rule, value, callback) {
            const mobileReg =
                /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
            if (!mobileReg.test(value)) {
                callback("请输入正确的身份证号码");
            } else {
                callback();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .ant-modal-body{
    padding: 15px;
}
.ant-form-item{
    margin-bottom: 10px;
}
/deep/ .ant-form-item-label{
    text-align: right;
    width: 100px;
    padding-right: 10px;
}
/deep/ .ant-form-item-control-wrapper{
    padding-left: 100px;
    margin-left: -100px;
    box-sizing: border-box;
}
</style>
